import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class XtrasService {

    constructor(
    ) {}

    filterEmptyParams(formData: { [key: string]: any }) {
        return Object.fromEntries(
            Object.entries(formData).filter(
                ([_, value]) => value !== null && value !== undefined && value !== ''
            )
        );
    }

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    scrollToTarget(el: HTMLElement) { // example <div #algo> scrollToTarget(algo)
        el.scrollIntoView({behavior: 'smooth'});
    }
}